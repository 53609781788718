<template>
  <div class="library-wrap">
    <div class="library-head">
        <el-form :inline="true" class="demo-form-inline" size="small" v-if="!uid" @submit.native.prevent>
          <el-form-item  class="title">
            <el-input v-model="formData.fileName" @clear="clearSearch" @blur="doSearch(1, true, true,true,true)" clearable placeholder="请输入搜索关键字" @keyup.enter.native="doSearch(1, true, true,true,true)">
              <el-select   @change="doSearch(1, true, true,true,true)"
              v-model="formData.target"
              filterable slot="prepend" placeholder="高级筛选">
              <el-option
                v-for="item in optionSearch"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
              </el-select>
              <el-button slot="append" icon="el-icon-search" @click="doSearch(1, true, true,true,true)"></el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="radio-wrap">
            <el-tabs v-model="radio" @tab-click="handleRadioEvent">
              <el-tab-pane label="全部" name="所有"></el-tab-pane>
              <el-tab-pane label="文档" name="文件类型"></el-tab-pane>
              <el-tab-pane label="图片" name="图片类型"></el-tab-pane>
              <el-tab-pane label="音频" name="音频类型"></el-tab-pane>
              <el-tab-pane label="视频" name="视频类型"></el-tab-pane>
            </el-tabs>
        </div>
    </div>
    <div class="tab-box" style="width:1180px;height: 32px;margin:15px auto;display:flex;justify-content:space-between;align-items: center;">
       <div>
          <el-button type="primary" size="small" v-if="haveDataShowFlag" @click="applyAuthBtn">申请授权</el-button>
       </div>
        <div style="display:flex;align-item:center">
          <span style="padding-right:5px">是否排除我的文档: <el-switch v-model="excludeMe" @change="doSearch(1,true,true,false,loadingList)" ></el-switch></span>
          <i :style="{'color': tabPattern == 1 ? '#409eFF' : '', 'font-size': '22px', 'margin-right': '5px'}" class="el-icon-set-up icons" @click="changeStyle(1)"></i>
          <i :style="{'color': tabPattern == 2 ? '#409eFF' : '', 'font-size': '22px'}" class="el-icon-menu icons" @click="changeStyle(2)"></i>
        </div>
    </div>
    <el-row :gutter="20" class="card" style="width:1200px;margin:0 auto;" v-if="showFiless.length > 0 && tabPattern == 2">
      <el-col :span="4" v-for="item in showFiless" :key="item.id">
        <div class="document-card" :style="{'--themecolor': hoverShowFlag && (item.id == hoverItemInfo.id) ? styleColor : ''}" @mousemove="handleMouseMoveEvent(item)" @mouseout="handleMouseOutEvent"
        @click="handleRowClickeEvent(item,null)">
             <div class="star-box" v-show="hoverShowFlag && (item.id == hoverItemInfo.id)">
                  <!-- <i class="el-icon-star-on" style="color: #EFCE4A;" title="取消收藏" @click.stop="handleCommandBtn({type: 'quStart', row: item})" v-if="item.isCollectionFlag == true"></i>
                  <i class="el-icon-star-off" title="收藏" @click.stop="handleCommandBtn({type: 'start', row: item})" v-else></i> -->
                 
                  <div @click.stop="">
                     <el-checkbox :value="item.selected" @change="onPageSelectEvent(item)">多选</el-checkbox>
                  </div>

                  <span>{{item.readCount == null ? 0 : item.readCount}}阅读</span>
              </div>

              <div class="document">
                <div class="document-top">
                    <div v-if="item.isApplyFlag == true" class="apply-tips">
                        <span>已申请</span>
                    </div>
                    <img v-show="item.coverImg" :src="item.coverImg|thumbnailUrl" :alt="item.coverImg|thumbnailUrl" />
                    <img :src="thumbIcon(item)" alt="" class="img-tip">
                </div>
                
                <!-- <img src="@/assets/select.png" alt="" class="select" v-if="item.selected" /> -->
            </div>
            <div class="document-middle">
                <el-tooltip effect="light" :content="item.name" placement="bottom-end">
                  <div class="title">
                    <!-- <p>标题：{{item.name}}</p> -->
                    <p>{{item.name}}</p>
                    <span v-show="hoverShowFlag && (item.id == hoverItemInfo.id)">所属人：{{item.ownerName}}</span>
                  </div>
                </el-tooltip>
            </div>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="20" style="width:1200px;margin:0 auto;" v-else-if="showFiless.length > 0 && tabPattern == 1">
      <ul class="similar-list" v-show="loadingList">
        <li v-for="liItems in showFiless" :key="liItems.id" >
           <h3  @click="handleRowClickeEvent(liItems,null)"><span v-html="$options.filters.replaceContent(liItems.name,formData.fileName,'key')"></span></h3>
           <dl>
              <!-- <dt :style="{ 'width': liItems.coverImg ? '120px' : '','border-radius':'12px','margin-right':liItems.coverImg ?'20px':''}">
                <img  style="border:1px solid rgba(0,0,0,.05)" v-show="liItems.coverImg" :src="liItems.coverImg|thumbnailUrl" @error="ImgError(liItems)" :alt="liItems.coverImg|thumbnailUrl" />
              </dt> -->
              <dd>
                <div class="tiem-and-content">
                    <div v-if="liItems.documentContentList" class="text">
                      <div class="content-item" v-if="liItems.documentContentList[0] && liItems.documentContentList[0].content =='内容未被公开，需申请查看'"><span class="contents" >内容未被公开，需申请查看</span></div>
                      <div v-else class="content-item" v-for="(item,index) in liItems.documentContentList" :key="index"  @click="handleRowClickeEvent(liItems,item.pageIndex)">
                        <span style="font-weight:bold;margin-right:10px;">[第 {{item.pageIndex+1}} 页] </span>
                        <span class="contents"  v-html="item.content"></span>
                      </div>
                    </div>
                </div>
                <div class="just">
                  <p>
                    <span class="time">{{liItems.createDate | date("yyyy-MM-dd") }}</span>
                    <span class="time marginLeft">{{liItems.ownerName}}</span>
                    <span class="time marginLeft">{{liItems.suffix || "文件夹"}}</span>
                    <span class="time marginLeft">{{liItems.size | byteSize}}</span>
                  </p>
                  <p class="apply">
                    <span v-if="liItems.isApplyFlag == true">已申请</span>
                      <!-- <el-button  type="primary" size="mini" title="取消收藏" @click.stop="handleCommandBtn({type: 'quStart', row: liItems})" v-if="liItems.isCollectionFlag == true">
                        
                          取消收藏
                      </el-button>
                      <el-button  type="primary" size="mini" title="收藏" @click.stop="handleCommandBtn({type: 'start', row: liItems})" v-else>
                        
                          收藏
                      </el-button> -->
                  </p>
                </div>
              </dd>
           </dl>
        </li>
      </ul>
      <el-table 
        v-show="!loadingList"
        :data="showFiless"
        size="small"
        height="calc(100vh - 360px)"
        ref="multipleTable"
        style="width: 100%;margin: 0 0 20px 0;"
        :row-key="row=>row.id"
        key="doctable"
        @selection-change="handleSelectionChange">
        <el-table-column
        type="selection"
        align="center"
        :reserve-selection="true"
        width="80">
      </el-table-column>
        <el-table-column
          label="文件名称"
          width="400">
          <template slot-scope="scope">
              <div class="file-box" @click="handleRowClickeEvent(scope.row)">
                  <img :src="thumbIcon(scope.row)" alt="" :class="{'doc-pic' : scope.row.type == 0}">
                  <span>{{scope.row.name}}</span>
                  <div v-if="scope.row.isApplyFlag == true" class="apply-tips">
                    <span>已申请</span>
                  </div>
              </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="ownerName"
          label="所有者"
          align="center">
        </el-table-column>
        <!-- <el-table-column
            label="大小"
            align="center">
            <template slot-scope="scope">
                {{ scope.row.size ? bytesToSize(scope.row.size) : '-' }}
            </template>
        </el-table-column> -->
        <el-table-column
            label="文件类型"
            align="center">
            <template slot-scope="scope">
                {{ scope.row.suffix || "文件夹" }}
            </template>
        </el-table-column>
        <el-table-column
          prop="readCount"
          label="阅读次数"
          align="center">
        </el-table-column>
        <el-table-column
          label="是否公开"
          align="center">
          <template slot-scope="scope">
                {{ scope.row.isPublic == 1 ? '可阅读' : '不可阅读' }}
            </template>
        </el-table-column>
        <el-table-column
          label="操作时间"
          align="center">
            <template slot-scope="scope">
              <span>{{scope.row.createDate | date}}</span>
            </template>
        </el-table-column>
        <!-- <el-table-column
            label="操作"
            align="center">
            <template slot-scope="scope">
                <el-button type="text" size="small" title="取消收藏" @click.stop="handleCommandBtn({type: 'quStart', row: scope.row})" v-if="scope.row.isCollectionFlag == true">
                   
                    取消收藏
                </el-button>
                <el-button type="text" size="small" title="收藏" @click.stop="handleCommandBtn({type: 'start', row: scope.row})" v-else>
                   
                    收藏
                </el-button>
            </template>
        </el-table-column> -->
      </el-table>
    </el-row>

    <div class="no-data-box" v-else-if="showFiless.length == 0">
          <img src="@/assets/no-file.svg" alt="">
          <span>暂无数据</span>
    </div>

    <div class="pagination" v-if="showFiless.length > 0">
        <el-pagination
        @current-change="pageChanged"
        layout="total,  prev, pager, next, jumper"
        :page-size="pageSize.pageSize"
        :total="pageSizeTotal.total">
      </el-pagination>
        <!-- <el-button type="primary" @click="doRequest">请求授权</el-button>
        <el-button @click="fastRequest = true">快速授权</el-button> -->
    </div>
    <ReadFile ref="readFile" />
  </div>
</template>

<script>
import {dollectionAPI, shareAPI} from 'liankong-ui-api' 
import ReadFile from "@/components/readFile.vue"
import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'
import { Store } from '@/utils/LocalCache.js'

//后缀映射
const suffixIconCache = {
  def: iconDefault,
  unknown: iconUnknown,

  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,

  mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}

export default {
  props: {
    uid: {
      type: Number,
      default: null
    }
  },
  filters:{
    replaceContent(content,fileName,className){
      if(fileName){
        return content.replace(new RegExp(`(${fileName})`,"gim"), `<span class='${className}'>$1</span>`)
      } else {
        return content
      }
    }
  },
  components: {ReadFile},
  data () {
    return {
      excludeMe:false, // 是否包含我的文档
      showBaiBu:false,
      cartViewKey:'request',
      fastSrc:require('../../assets/u23.png'),
      submitLoading:false,
      loading: false,
      loadingList:false,
      selectUser: null,
      formData: {
        fileName: null,
        start:null,
        end:null,
        target:''
      },
      viewerKey:'find',
      pager: {
        total: 0,
        pageSize: 12,
      },
      optionSearch:[{value:'',label:'所有'},{value:'1',label:'所有者'},{value:'2',label:'标签'},{value:'3',label:'文档标题'},{value:'4',label:'文档内容'}],
      visible: false,
      fastRequest: false,
      request: {
        files: []
      },
      showFiless:[],
      radio: '所有',
      pageNums: 1,
      startList: [], // 收藏列表
      tabPattern: 1,
      multipleSelection: [],
      haveDataShowFlag: false,
      clearFile: {},
      hoverShowFlag: false,
      hoverItemInfo: {},
      styleColor: '#fff'
    }
  },
  computed: {
    checkType(){
      return this.uid ? '' : 'selection'
    },
    
    //搜索视图
    searchViewer() {
      return this.$store.state.index.viewer[this.viewerKey];
    },
    pageSize(){
      return this.$store.state.index.viewer[this.viewerKey].query
    },
    pageSizeTotal(){
      return this.$store.state.index.viewer[this.viewerKey].paging
    },
    showFiles(){
      return this.searchViewer.showFiles.map(page => {
        page.selected =  false
        return page
      })
    },
    viewerKeys(){
        return this.$store.state.cartForm.viewerKey
    },
      //文件列表
    files(){
      const viewer = this.$store.state.cart.views[this.viewerKeys]
      return viewer ? viewer.docList : []
    },
    // 申请记录数据
    requestList() {
      return this.$store.state.request.views.sender.list|| [];
    },
  },
  watch: {
     pageNums(newValue) {
      this.pageNums = newValue;
    },
    showFiless(vals) {
      this.dataFormatEvent();
      this.applyFormatEvent();
    },
    startList(vals) {
      this.dataFormatEvent();
    },
    requestList() {
      this.applyFormatEvent();
    }
  },
  methods: {
    ImgError(liItems){
      liItems.coverImg = ''
      return liItems
    },
    clearSearch(){
      this.loadingList = false
      this.formData.fileName = ''
      this.doSearch(1,true,true,false,this.loadingList)
    },
    // 图标
    thumbIcon(doc){
        let iconKey = "unknown"
        if(suffixIconCache.hasOwnProperty(doc.suffix)){
            iconKey = doc.suffix
        }
        return suffixIconCache[iconKey] 
    },
    // 表格选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if(this.multipleSelection.length > 0) {
        this.haveDataShowFlag = true;
      } else {
        this.haveDataShowFlag = false;
      }
    },
    // 申请授权
    applyAuthBtn() {
      if(this.tabPattern == 1) {
        this.$store.commit("cart/resetRequest")
        let idsMultipleSelection = []
         this.multipleSelection.forEach(mulItem=>{
          idsMultipleSelection.push(mulItem.id)
            this.$store.commit("cart/push", {
              viewerKey: this.cartViewKey,
              doc: mulItem,
            })
            this.showFiless.filter(item=>{
              if(item.id==mulItem.id){
                this.$set(mulItem,'selected',true)
              }
            })
            this.$nextTick(() => this.$forceUpdate())
        })
        this.$store.dispatch('doc/batchCheckDocumentSharePermission',idsMultipleSelection).then(res=>{
          if(res.code==0){
              if(res.data.length>0){
                this.$message.error('文件中存在不可申请文件')
              } else {
                this.$store.commit('setCartShow', true)
              }
          } else {
            this.$message.error(res.message?res.message:'文件中存在不可申请文件')
          }
        })
        // this.$store.commit('setCartShow', true)
      } else {
        let showFilessIds = this.multipleSelection.map(item=>item.id)
        this.$store.dispatch('doc/batchCheckDocumentSharePermission',showFilessIds).then(res=>{
          if(res.code==0){
              if(res.data.length>0){
                this.$message.error('文件中存在不可申请文件')
              } else {
                this.$store.commit('setCartShow', true)
              }
          } else {
            this.$message.error(res.message)
          }
        })
        // this.$store.commit('setCartShow', true)
      }
    },
    // 取消选择
    toggleSelection(rows) {
      let that = this;
        if (rows) {
          this.showFiless.forEach(row => {
            if(row.id == rows.id) {
              that.$refs.multipleTable.toggleRowSelection(row, false);
            }
          });
        } else {
          that.$refs.multipleTable.clearSelection();
        }
    },
    // 鼠标滑过
    handleMouseMoveEvent(item) {
      this.hoverShowFlag = true;
      this.hoverItemInfo = item;
      this.styleColor = '#F5F7FA'
    },
    handleMouseOutEvent(){
      this.hoverShowFlag = false;
      this.styleColor = '#fff'
    },
    // 卡片 移除
    clearCardChecked(rows){
      if (rows) {
          this.showFiless.forEach(row => {
            if(row.id == rows.id) {
              this.$set(row,'selected',false)
            }
          });
      }
    },
    onFolderNameClick(){
      
    },
    pageChanged(num){
      this.pageNums = num;
      this.doSearch(num,true,false,false,this.loadingList)
      
      this.toggleSelection(this.clearFile);
    },
    handleRadioEvent(val) {
        this.systemTag = [];
        this.systemTag.push(val.name);
        this.newObj = {
            systemTag: val.name == '所有' ? [] : this.systemTag
        }
        this.doSearch(1,true,true,false,this.loadingList)
    },
    //快速搜索
    doSearch(pageNum,reset,noReset,showList,flag){
      let {fileName,start,end,target} = this.formData
      this.$store.commit("index/updateQuery",{
        vkey:'find',
        query:{
          keyword:fileName,
          excludeMe:this.excludeMe,
          systemTag: this.newObj.systemTag,
          startDate: start ? start : '',
          endDate: end ? end :'' ,
          pageNum,
          pageSize:18,
          target:JSON.stringify(target)=='[]'? '' : `${target}`.replace(/"/g,'')
        }
      });
      this.$store.dispatch("index/indexByQuery", {
        vkey: "find",
        reset,
        noReset
      }).then(res=>{
       if(res) {
           this.loadingList = flag&&fileName?flag:false
          this.showBaiBu = true
          this.showFilesList(showList)
       }
      });
    },
    showFilesList(showList){
      const viewer = this.$store.state.cart.views[this.viewerKeys]
      const showFiles = this.$store.state.index.viewer[this.viewerKey].showFiles
      if(showList){
        this.showFiless = showFiles.map(page => {
            page.selected =  false
            return page
        })
      }
      else if(!showList && viewer.docList){
        if(viewer.docList.length==0){
          this.showFiless = showFiles.map(page => {
              page.selected =  false
              return page
          })
        } else {
          this.showFiless = [...showFiles]
          this.showFiless.forEach(item=>{
            viewer.docList.forEach(ele=>{
              if(item.id==ele.id){
                item.selected = ele.selected ? ele.selected : false
              }
            })
          })
        }
      } else {
        this.showFiles = [...showFiles]
        this.showFiless.forEach(item=>{
        viewer.docList.forEach(ele=>{
          if(item.id==ele.id){
            item.selected = ele.selected ? item.selected : false
          }
        })
        })
      }
    },
    onPageSelectEvent(rows){
      
      if(!rows.selected){
        this.$store.commit("cart/push", {
          viewerKey: this.cartViewKey,
          doc: rows,
        })
        this.showFiless.filter(item=>{
          if(item.id==rows.id){
            this.$set(rows,'selected',true)
          }
        })
        this.$nextTick(() => this.$forceUpdate())
        this.multipleSelection.push(rows);
      }
      else {
        this.showFiless.filter(item=>{
          if(item.id==rows.id){
            this.$set(rows,'selected',false)
          }
        })
        this.$store.commit("cart/remove", {
          viewerKey: this.cartViewKey,
          doc: rows,
        })
         this.$nextTick(() => this.$forceUpdate())

         this.multipleSelection.forEach((filItems, ind)=>{
          if(filItems.id == rows.id) {
            this.multipleSelection.splice(ind, 1)
          }
         })
      }

      if(this.files && this.files.length > 0){
          this.haveDataShowFlag = true;
      } else {
          this.haveDataShowFlag = false;
      }
      
    },
    // 弹框确定申请
    doRequest(){
      if(this.request.files.length > 0){
        this.visible = true
      }else{
        this.$message.error("请选择要申请的文件!")
      }
    },
    removeFile(index,row){
      this.request.files.splice(row,1);
    },
   // 提交申请
    doRequestSubmit(){
      this.submitLoading = true;
      const docId = this.request.files.map(item => item.id)
      const _this = this;
      this.$store.dispatch("cart/shareResourceRequest", {
        docId,
      }).then(res=>{
        if(res.code == 0){
          this.$message.success("申请已发送!")
        }else{
          this.$message.error(res.message)
        }
        this.visible = false
        this.request.files = []
        this.submitLoading = false
        _this.$refs.multipleTable.clearSelection();
      })
    },
    handleCommandBtn({type, row}) {
        switch(type) {
            case "start":
                this.addFavorite(row);
                break;
            case "quStart":
                this.quStarData(row);
            break;
        }
    },
    // 添加收藏
    addFavorite(docInfo) {
      let num = this.pageNums
      this.$store.dispatch("collection/addDollection", docInfo.id)
          .then((data) => {
              this.$message.success("收藏成功");
              this.doSearch(num,true,false,false,this.loadingList);
              this.onLoadStarPage();
              this.dataFormatEvent();
          }).catch((err) => {
              this.$msg.error("收藏发生错请稍后再试");
          });
    },
    // 取消收藏
    quStarData(item) {
        let num = this.pageNums
        dollectionAPI.removeRecoredId(item.id).then(res=>{
            if(res.code == 0) {
                this.$message.success("取消收藏成功");
                this.doSearch(num,true,false,false,this.loadingList);
                this.onLoadStarPage();
                this.dataFormatEvent();
                item.isCollectionFlag = false;
            } else {
                this.$message.error(res.message); 
            }
        }).catch(error=>{
            this.$message.error(error);
        })
    },
    // 获取收藏列表
    onLoadStarPage(){
        // this.$store.dispatch("collection/dollectionList", { reset: true, query: {
        //   pageNum: 1,
        //   pageSize: 99999999
        // } }).then(res=>{
        //     this.startList = this.$store.state.collection.dollectionList;
        // })
        dollectionAPI.dollectionAll().then(res=>{
            if(res.code == 0) {
                this.startList = res.data;
            }
        })
    },
    // 收藏 与 文档库 数据比较
    dataFormatEvent() {
      let falg = false;
      for (var i = 0; i < this.showFiless.length; i++) {
        for (var j = 0; j < this.startList.length; j++) {
          if (this.showFiless[i].id == this.startList[j]) {
            falg = true;
            this.$set(this.showFiless[i], 'isCollectionFlag', falg);
          }
        }
      }
    },
    // 申请记录 数据列表
    getRequestList(){
      let query = {
        end:'',
        start:'',
        name: null,
        pageNum: 1,
        pageSize: 999999,
        type:[],
        state:[]
      }
      this.$store.state.request.views.sender.list =[];
      this.$store.dispatch("request/loadSendByMe",{
        reset: true,
        query:query
      }).then(res => {
      })
    },
    // 申请数据 与 文档库比较
    applyFormatEvent(){
      for (var i = 0; i < this.showFiless.length; i++) {
        for (var j = 0; j < this.requestList.length; j++) {
          if (this.showFiless[i].name == this.requestList[j].shareName) {
            this.$set(this.showFiless[i], 'isApplyFlag', true);
          }
        }
      }
    },
    // tab切换
    changeStyle(val) {
      let that = this;
      this.tabPattern = val;
      if(this.tabPattern == 1) { // 表格下 选中的数据也要保持选中
        this.multipleSelection.forEach(keys=>{
            this.showFiless.forEach(row=>{
              if(row.id == keys.id) {
                setTimeout(()=>{
                  that.$refs.multipleTable.toggleRowSelection(row, true);
                }, 500)
              }
            })
        })
        // this.loadingList = false
      } else {
        this.showFiless.forEach(items=>{
            this.$set(items, 'selected', false)
             this.$store.commit("cart/remove", {
              viewerKey: this.cartViewKey,
              doc: items,
            })
        })
        
        this.multipleSelection.forEach(keys=>{
            this.showFiless.forEach(row=>{
              if(row.id == keys.id) {
                this.$store.commit("cart/push", {
                  viewerKey: this.cartViewKey,
                  doc: row,
                })
                this.$set(row, 'selected', true)
              }
            })
        })
        this.$nextTick(() => this.$forceUpdate())
      }
    },
    handleRowClickeEvent(row,pageIndex) {
      this.readDocEvent(row,pageIndex)
    },
    // 阅读
    readDocEvent(row,pageIndex) {

        if(row.documentId != null){
            row.id = row.documentId
        }
        

        let suffixs = 'ofd'
        if(row.suffix == 'mp3' || row.suffix == 'mp4' || row.suffix == 'zip'){
          suffixs = row.suffix
        }
        shareAPI.getReaderUrl('', row.documentId || row.id, row.documentId,row.name, suffixs)
        .then(({ code, data, message }) => {
            if (code == 0) {
              if(row.suffix == 'zip'){
                this.$message.error('zip格式不支持阅读！')
                return
              }
                //获取到阅读地址，跳转阅读服务
                if(Store.get("myPreference")){
                    if(Store.get("myPreference").reader_model == '1'){
                        window.open(this.$options.filters['readerUrl'](data.url+'&pageIndex='+pageIndex));
                    }else{
                        this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url+'&pageIndex='+pageIndex))
                    }
                }else{
                    this.$refs['readFile'].showReadFile(this.$options.filters['readerUrl'](data.url+'&pageIndex='+pageIndex))
                }
            } else if(code == 1001) {
                // this.$message.error('权限不足，请申请阅读');
                this.$refs.multipleTable.toggleRowSelection(row, true);
                this.$store.commit("cart/resetRequest")
                  this.multipleSelection.forEach(mulItem=>{
                      this.$store.commit("cart/push", {
                        viewerKey: this.cartViewKey,
                        doc: mulItem,
                      })
                      this.showFiless.filter(item=>{
                        if(item.id==mulItem.id){
                          this.$set(mulItem,'selected',true)
                        }
                      })
                      this.$nextTick(() => this.$forceUpdate())
                  })
                // this.$store.commit("cart/push", {
                //     viewerKey: this.cartViewKey,
                //     doc: row,
                //   })
                this.$store.commit('setCartShow', true)

            } else {
                this.$message.error(message || '您不能查看当前文件') 
            }
        })
    },
    bytesToSize(value) {
          if(value) {
             var unitArr = new Array("B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB");
              var index = 0;
              var srcsize = parseFloat(value);
              index = Math.floor(Math.log(srcsize) / Math.log(1024));
              var size = srcsize / Math.pow(1024, index);
              size = size.toFixed(2);//保留的小数位数

              return size + unitArr[index];
          }
      }
  },
  created () {

  },
  mounted () {
    this.handleRadioEvent({
      name: this.radio
    })
    this.onLoadStarPage();
    this.getRequestList()
    this.loadingList = false
    // 移除
    this.$ebus.$on('clear',file=>{
      this.clearFile = file;
      if(this.tabPattern == 1) {
        this.toggleSelection(file)
      } else {
        this.clearCardChecked(file)
      }
      
    })
  },
}
</script>
<style> .key{
    color: red;
    border-bottom:1px solid red;
  }
  .content {
    color: red;
  }</style>
<style lang='less' scoped>
  @import "../../styles/index";

  .library-wrap {
    margin-top: -43px !important;
  }
  .library-head {
    background: #F7F8FA;
    border-bottom: 1px solid #e8e2e2;
    height: 150px;
  }
  .card {
    height: calc(100vh - 350px);
    overflow: auto;
  }
  .demo-form-inline {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 50px;
    h3 {
      margin: 0;
      padding: 0;
    }
    /deep/ .el-form-item {
      height: 40px;
      margin: 0 15px;

      .el-form-item__content {
        height: 45px;
        
        .el-input {
          width: 550px;
          height: 40px;
         .el-input-group__prepend{
          background-color: #fff;
          }

          .el-input__inner {
            height: 100%;
          }
        }
        .el-select {
          .el-select__tags {
            transform:translateY(0)
          }
          .el-input {
          width: 100px;
          height: 40px;
          .el-input__inner {
            height: 100%;
          }
        }
        }
        .el-date-editor  {
          width: 180px;
        }
        .el-input-group__append {
          background: #409EFF;
          color: #fff;
          font-size: 18px;
          padding: 0 25px;
        }
      }
    }
    .el-button {
      height: 39px;
    }
  }
  .radio-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    /deep/ .el-tabs {
      .el-tabs__header {
        margin: 0px;
        .el-tabs__nav-wrap::after {
          height: 0px;
        }
        .el-tabs__active-bar {
          height: 3px;
        }
      }
      .el-tabs__item {
        width: 60px;
        padding: 0;
        text-align: center;
      }
    }
  }
  .no-data-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -1%;
      margin-right: 4%;
      img {
          width: 380px;
          height: 280px;
      }
      span {
          color: #bdc1cd;
          font-size: 16px;
          margin-top: 15px;
          font-weight: 100px;
      }
  }
  .user-avatar {
    vertical-align: middle;
    margin-right: 8px;
  }

  .btn {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fast {
    /deep/ .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;

        .el-dialog__headerbtn {
          display: none;
        }
      }

      .el-dialog__body {
        display: flex;
        justify-content: center;

        .el-image {
          width: 50%;
        }
      }

      .el-dialog__footer {
        display: flex;
        justify-content: center;
      }
    }
  }
  .document-card {
      height: 295px;
      margin: 0;
      padding: 10px 12px;
      // background: #F5F7FA;
      background: var(--themecolor);
  }
  .document {
    // height: 330px;
    height: 200px;
    cursor: pointer;
    user-select: none;
    position: relative;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    // background: pink;
    /deep/ .el-card__body {
      padding: 10px;
    }
  }
  // .document:hover {
  //   border-radius: 5px;
  //   box-shadow: 0px 2px 16px 0px rgba(18, 55, 100, 0.2);
  // }

  .document-top {
    position: relative;
    // padding: 10px;
    height: 200px;
    overflow: hidden;
    .img-tip {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 18px;
      height: 18px;
    }
    .apply-tips {
      position: absolute;
      left: 0px;
      bottom: 3px;
      span {
        border: 1px solid #ccc;
        padding: 3px;
        border-radius: 5px;
        font-size: 13px;
        color: #D04C38
      }
    }
  }

  .document-top img {
    width: 100%;
    height: 100%;
  }

  .document-middle {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: @h6;
    // padding: 10px 20px;
    font-size: 14px;
    color:#333;
    margin-top: 10px;
    p {
      margin: 0;
      padding: 0;
      height: 18px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
  .star-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 0 5px 0;
    span {
      font-size: 13px;
    }
    /deep/ .el-checkbox {
      .el-checkbox__label {
        padding-left: 5px;
      }
    }
  }

  .document-bottom {
    // padding-bottom: 30px;
    display: flex;
    justify-content: space-evenly;

    .btn{
      font-size: 16px;
    }
  }

  .select{
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .pagination {
    width: 1200px;
    margin: 0 auto;
    margin-top:20px;
    .el-pagination {
      text-align: left;
      margin-bottom: 10px;
    }
  }
  /deep/ .el-table {
    .file-box {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
            display: block;
        }
        >span {
            display: block;
            width: 240px;
            height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            white-space: nowrap;
            // -webkit-box-orient: vertical;
        }
        .apply-tips {
          position: absolute;
          right: 30px;
          top: 1px;
          span {
            border: 1px solid #ccc;
            padding: 2px;
            border-radius: 5px;
            font-size: 12px;
            color: #D04C38
          }
        }
    }
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
.similar-list{
  margin: 0;
  padding: 0;
  height:calc(100vh - 350px);
  overflow: auto;
  li {
    list-style: none;
    margin: 0 0 30px 0;
    cursor: pointer;
    h3 {
      margin: 0 0 10px 0;
      padding: 0;
      color:#2440b3;
      font-size: 18px;
      font-weight: 400;
     .key{
          color:red;
    }
      span {
        border-bottom: 1px solid #2440B3;
         
      }
      // border-bottom: 1px solid #2440B3;
    }
    dl {
      margin: 0;
      padding: 0;
      display: flex;
      dt {
        height: 90px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      dd {
        flex: 1;
        margin: 0;
        padding: 0;
        .tiem-and-content {
          overflow: hidden;

          .text {
            color: #666;
            font-size: 13px;
            
            .content-item{
              margin-bottom: 10px;
            }
            .contents{
              color: #999;
              font-size: 13px;
              line-height: 20px;
            }
          }
        }
        .just {
          display: flex;
          justify-content: space-between;
          p{
            margin: 0;
          }
          .apply {
               span {
              border: 1px solid #ccc;
              padding: 3px;
              border-radius: 5px;
              font-size: 13px;
              color: #D04C38;
              margin-right:10px;
              box-sizing: border-box;
            }
          }
        }
      .time {
          color:#9195A3;
          font-size: 13px;
        }
        .marginLeft {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>